import { Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";

const DrawerButtons = ({ setIsDrawerOpen, isDrawerOpen, onNewChat }) => {
  return (
    <Box className="flex justify-between w-full">
      <IconButton
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        className="!bg-slate-700 !rounded-lg !p-2 hover:!bg-slate-600"
      >
        {isDrawerOpen ? (
          <MenuOpenIcon className="text-white" />
        ) : (
          <MenuIcon className="text-white" />
        )}
      </IconButton>
      <IconButton
        onClick={() => onNewChat()}
        className="!bg-slate-700 !rounded-lg !p-2 hover:!bg-slate-600"
      >
        <AddIcon className="text-white" />
      </IconButton>
    </Box>
  );
};

export default DrawerButtons;
