import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import toast from "react-hot-toast";
import OTPInput from "react-otp-input";
import OTPTimer from "./OTPTimer";
import { authRegister, sendOTPEmail } from "../services/authService";
import { generateOTP } from "../utils/generateOTP";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../providers/userProvider";
import routeConstant from "../utils/routeConstant";
import { getUserDetails } from "../services/userService";

const OTPModal = ({
  userDetails,
  isOTPModalOpen,
  setIsOTPModalOpen,
  currentOTP,
  setCurrentOTP,
}) => {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);
  const [otpValue, setOtpValue] = React.useState();
  const [isExpired, setIsExpired] = React.useState(false);
  const [resetTimer, setResetTimer] = React.useState(false);
  const [redirectUser, setRedirectuser] = React.useState(null);

  React.useEffect(() => {
    if (user) {
      setTimeout(() => {
        setRedirectuser(routeConstant?.dashboard);
      }, 2000);
    }
  }, [user]);

  if (redirectUser) {
    navigate("/");
  }

  const handleTimerComplete = (expired) => {
    setIsExpired(expired);
  };

  const handleClose = () => {
    setIsOTPModalOpen(false);
  };

  const verifyOtp = async () => {
    if (currentOTP === otpValue) {
      const payload = {
        name: "",
        username: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
      };
      const response = await authRegister(payload);
      if (response.status === 200) {
        const userResponse = await getUserDetails(response.data.access_token);
        if (userResponse.status === 200) {
          // TODO: Do something with user data
          setIsOTPModalOpen(false);
          toast.success("Welcome to EvattAi!");
          setRedirectuser(true);
        }
      }
    } else {
      toast.error("Wrong OTP, Please enter the correct OTP");
    }
  };

  const handleResendOtp = async () => {
    // Generating new OTP
    const newOTP = generateOTP();
    setCurrentOTP(newOTP);

    const payload = {
      name: userDetails.name,
      email: userDetails.email,
      subject: "",
      otp: newOTP,
    };
    await sendOTPEmail(payload);

    // For Timer
    setIsExpired(false);
    setResetTimer((prev) => !prev);

    toast.success("OTP sent successfully! Please check your email");
  };

  return (
    <Modal open={isOTPModalOpen} onClose={handleClose}>
      <Fade in={isOTPModalOpen}>
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[600px] shadow-lg p-4 flex flex-col">
          <Typography className="bg-slate-800 !text-[25px] !font-extrabold !text-white !px-4 !py-6 !rounded-t-lg flex gap-3 items-center">
            <MailOutlineIcon fontSize="large" />
            Email Verification
          </Typography>
          <Box className="bg-gray-900 flex flex-col items-center gap-6 !rounded-b-lg p-4 pt-6">
            <Box className="flex flex-col items-center gap-2">
              <Typography className="!text-[23px] !font-[700] !text-white">
                User Details
              </Typography>
              <Box className="flex flex-col items-center">
                <Box className="flex gap-2">
                  <Typography className="!text-[16px] !font-[600] !text-white">
                    Name:
                  </Typography>
                  <Typography className="!text-[16px] !text-gray-400">
                    {userDetails.name}
                  </Typography>
                </Box>
                <Box className="flex gap-2">
                  <Typography className="!text-[16px] !font-[600] !text-white">
                    Email:
                  </Typography>
                  <Typography className="!text-[16px] !text-gray-400">
                    {userDetails.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="flex flex-col items-center gap-2">
              <Typography className="!text-[23px] !font-[700] !text-white">
                Enter the OTP
              </Typography>
              <OTPInput
                containerStyle="flex gap-4 justify-center"
                value={otpValue}
                onChange={setOtpValue}
                numInputs={6}
                renderInput={(props) => <input className="w-full" {...props} />}
                inputStyle="bg-transparent border border-gray-600 text-[24px] text-white rounded-md !w-[70px] h-[70px]"
              />
            </Box>
            <Box className="flex flex-col items-center gap-2 pb-2">
              <Button
                className={`!border !border-black !rounded-xl !py-2 !px-20 !text-[16px] !font-bold !flex !justify-center !items-center ${
                  otpValue
                    ? "!bg-[#00A67E] !text-white"
                    : "!bg-gray-800 !text-slate-400"
                }  !capitalize`}
                disabled={!otpValue}
                onClick={verifyOtp}
              >
                Verify
              </Button>
              <Box className="flex flex-col items-center">
                <Typography className="!text-[16px] pt-2 !font-[400] !text-white flex gap-1 items-center">
                  {!isExpired && (
                    <>
                      <span>Time Remaining:</span>
                      <OTPTimer
                        onTimerComplete={handleTimerComplete}
                        resetTimer={resetTimer}
                      />
                    </>
                  )}
                </Typography>
                <Typography className="!text-[16px] !font-[400] !text-white flex gap-1 items-center">
                  Didn't receive the Code?{" "}
                  <Button
                    className="!m-0 !p-0 !capitalize !bg-transparent !text-white !text-[16px] !font-[600] hover:!underline"
                    onClick={handleResendOtp}
                    disabled={!isExpired}
                  >
                    Resend
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default OTPModal;
