/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import routeConstant from "../utils/routeConstant";
import InputField from "../components/inputField";
import AuthButtons from "../components/authButtons";
import { UserContext } from "../providers/userProvider";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Logo from "../assets/image.png";
import Cookies from 'js-cookie';
import useFetch from "../api/HttpRequest";

const SignIn = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // Removed dispatch
  // const dispatch = useDispatch();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data: loginData, error: loginError, fetchData: loginFetch } = useFetch('/user/login', {
    method: 'POST',
    data: formData,
    silent: false,
    successMessage: 'Welcome to EvattAi!',
  });

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (loginData?.status) {
      setTimeout(() => {
        const accessToken = loginData.data.accessToken;
        Cookies.set('accessToken', accessToken);
        window.location.href = routeConstant?.dashboard;
      }, 2000)
    }
  }, [loginData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await loginFetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="flex flex-col items-center min-h-screen gap-20 py-10 bg-gray-900">
      <Link
        to={routeConstant.dashboard}
        className="!text-[50px] !font-bold !text-white"
      >
        <img src={Logo} alt="logo" width={250} />
      </Link>
      <Box className="flex flex-col gap-4 w-full px-5 lg:w-1/2 xl:w-[30%]">
        <Box>
          <Typography className="!text-[22px] !font-semibold !text-white">
            Sign In
          </Typography>
          <Typography className="!text-[12px] !font-bold !text-gray-400">
            Enter email address and password
          </Typography>
        </Box>
        <InputField
          placeholder="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <InputField
          placeholder="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
        />
        <Box className="flex justify-center">
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="!border !border-black !rounded-xl !py-2 !px-20 !text-[16px] !font-bold !flex !justify-center !items-center !bg-[#00A67E] !text-white !capitalize"
          >
            {loading ? "Signing In..." : "Login"}{" "}
          </Button>
        </Box>

        {/* Display error message */}
        {error && (
          <Typography className="!text-center !text-[12px] !font-bold !text-red-500">
            {error}
          </Typography>
        )}

        <div className="flex flex-col gap-2 mt-2">
          <Typography className="!text-center !text-[12px] !font-bold !text-gray-400 justify-center items-center flex gap-1">
            Don't have an account?
            <Link to="/signup" className="hover:!underline">
              Sign Up
            </Link>
          </Typography>
          <Typography className="!text-center !text-[12px] !font-bold !text-gray-400 justify-center items-center flex gap-1">
            Forgot your password?
            <Link to="/forget-password" className="hover:!underline">
              Reset Password
            </Link>
          </Typography>

          <Typography className="!text-center !text-[14px] !font-bold !text-gray-400">
            Or
          </Typography>
          <Box className="flex items-center justify-center gap-4">
            <AuthButtons type="Google" mode="signin" />
            {/* <AuthButtons type="Apple" mode="signin" /> */}
          </Box>
          {/* <Box className="flex justify-center">
          <AuthButtons type="Facebook" mode="signin" />
        </Box> */}
        </div>
      </Box>
    </Box>
  );
};

export default SignIn;
