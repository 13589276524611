import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { planModalData } from "../utils/constants.js";
import Cookies from "js-cookie";
import useFetch from "../api/HttpRequest.js";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog.jsx";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const UpgradePlanModal = ({
  isUpgradePlanModalOpen,
  setIsUpgradePlanModalOpen,
  subscriptionValidity
}) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');

  const { data: subscriptionResponse, fetchData: fetchSubscriptionAdd } = useFetch('/subscription/add', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const { data: trailSubscriptionResponse, fetchData: fetchTrailSubscriptionAdd } = useFetch('/subscription/trail/add', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const { data: subscriptionCancel, fetchData: fetchSubscriptionCancel } = useFetch('/subscription/cancel', {
    method: 'GET',
    accessToken,
    silent: true,
    successMessage: 'Subscription successfully canceled!',
  });

  const handleClose = () => {
    setIsDialogOpen(true);
  };
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleModelClose = () => {
    setLoading(false);
    setIsUpgradePlanModalOpen(false);
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmCancel = async () => {
    try {
      await fetchSubscriptionCancel();

      if (subscriptionCancel?.status === "success") {
        console.log("Subscription successfully canceled!");
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        console.error("Failed to cancel subscription:", subscriptionCancel?.message);
        toast.error(subscriptionCancel?.message);
      }
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error canceling subscription:", error);
      toast.error("Error canceling subscription:");
      setIsDialogOpen(false);
    }
  };

  const handleEnterpriseClick = () => {
    if (accessToken) {
      setButtonDisabled(true);
      fetchSubscriptionAdd();
    } else {
      navigate('/signin');
    }
  }

  const handleButtonClick = (index, buttonText) => {
    if (isEnterprise) {
      handleClose();
    } else if (buttonText === 'Continue with Free') {
      if (isTrail) {
        setIsUpgradePlanModalOpen(false);
      } else {
        setButtonDisabled(true);
        setLoading(true);
        fetchTrailSubscriptionAdd();
      }
    } else {
      handleEnterpriseClick();
      setLoading(true);
    }
  };

  useEffect(() => {
    if (subscriptionResponse?.status) {
      window.location.href = subscriptionResponse.data.payment_link;
    } else {
      setButtonDisabled(false);
    }
  }, [subscriptionResponse]);

  useEffect(() => {
    if (trailSubscriptionResponse?.status) {
      handleModelClose();
      window.location.href = trailSubscriptionResponse.data.payment_link;
    } else {
      setButtonDisabled(false);
    }
  }, [trailSubscriptionResponse]);

  const [isEnterprise, setIsEnterprise] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isTrail, setIsTrail] = useState(false);

  useEffect(() => {
    if (subscriptionValidity) {
      setIsEnterprise(subscriptionValidity.enterprise);
      setIsTrail(subscriptionValidity.trail);
    }
  }, [subscriptionValidity]);

  return (
    <Modal
      open={isUpgradePlanModalOpen}
      onClose={() => setIsUpgradePlanModalOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isUpgradePlanModalOpen}>
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-[1000px] shadow-lg p-4 flex flex-col outline-none">
          <Typography className="bg-slate-800 !text-[20px] md:!text-[30px] !font-extrabold !text-white !px-4 !py-6 !rounded-t-lg flex justify-between items-center">
            Upgrade Your Plan
            <CloseIcon className="cursor-pointer" onClick={() => setIsUpgradePlanModalOpen(false)} />
          </Typography>
          <Box className="bg-gray-900 flex flex-col md:flex-row gap-2 min-h-[400px] !rounded-b-lg  ">
            {planModalData.map(
              ({ type, description, price, priceDescription, buttonText, chatGptVersion, features }, index) => (
                <Box
                  key={type}
                  className={`flex flex-1 flex-col  gap-7 p-4 items-center ${index !== 2 && "md:border-r border-gray-600"
                    } ${index !== 0 && "border-t md:border-t-0 border-gray-600"}`}
                >
                  <Box className="flex flex-col items-center w-full gap-0">
                    <Typography className="!text-[20px] md:!text-[30px] !font-extrabold !text-white text-center">
                      {type}
                    </Typography>
                    <Typography className="!text-[14px] md:!text-[16px] !font-[550] !text-gray-400 text-center">
                      {description}
                    </Typography>
                  </Box>
                  <Box className="flex flex-col items-center w-full gap-0">
                    <Typography className="!text-[33px] !font-[550] !text-white">
                      ${price}
                    </Typography>
                    <Typography className="!text-[13px] !font-[550] !text-gray-400">
                      {priceDescription}
                    </Typography>
                  </Box>
                  <Box className="flex flex-col items-center w-full gap-2 text-center">
                    {features.map((feature, idx) => (
                      <Typography
                        key={idx}
                        className="!text-[14px] !text-gray-400"
                      >
                        {feature}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    className={`flex justify-center !text-white !text-[16px] !font-extrabold !capitalize py-3 !rounded-3xl cursor-pointer hover:!bg-[#57c1a8] !w-[90%] ${index === 0 && isEnterprise
                      ? "!bg-gray-500 cursor-not-allowed !opacity-50"
                      : "!bg-[#00A67E] hover:!bg-[#57c1a8]"
                      }`}
                    onClick={() => handleButtonClick(index, buttonText)}
                    disabled={index === 0 && isEnterprise || buttonDisabled}
                  >
                    {loading && (buttonText === "Upgrade to Enterprise" || buttonText === "Continue with Free") ? <CircularProgress size={24} /> : buttonText}
                  </Button>
                  <ConfirmationDialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    onConfirm={handleConfirmCancel}
                    title="Cancel Subscription"
                    message="Are you sure you want to cancel your subscription? This action cannot be undone."
                  />
                </Box>
              )
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UpgradePlanModal;
