import { Box, TextField } from "@mui/material";
import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";

const ChatInput = ({
  userQuery,
  setUserQuery,
  onSubmit,
  attachment,
  setAttachment,
  isLoading,
  selectedPdfName,
  setSelectedPdfName,
  setFileSizeWarning,
}) => {
  const handleClose = () => {
    setAttachment(null);
    setUserQuery("");
    setSelectedPdfName("");
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) { // 5MB in bytes
      setAttachment(null);
      setSelectedPdfName("");
      setFileSizeWarning(false); // Reset warning state
      setTimeout(() => setFileSizeWarning(true), 0); // Trigger warning
      return;
    }
    setAttachment(file);
    setSelectedPdfName(file.name);
    event.target.value = "";
  };

  return (
    <Box className="flex flex-col text-white bg-slate-800 rounded-3xl">
      {selectedPdfName && (
        <Box className="flex items-center justify-between px-4 py-2 bg-slate-700 rounded-t-3xl">
          <Box className="flex items-center gap-2">
            <PictureAsPdfIcon fontSize="medium" className="text-white" />
            <span>{selectedPdfName}</span>
          </Box>
          <CloseIcon
            fontSize="small"
            className="text-white cursor-pointer"
            onClick={handleClose}
          />
        </Box>
      )}
      <TextField
        variant="standard"
        margin="normal"
        onChange={(e) => setUserQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey && !isLoading) {
            e.preventDefault();
            onSubmit(userQuery);
          }
        }}
        value={userQuery}
        fullWidth
        multiline
        maxRows={4}
        disabled={isLoading}
        placeholder={isLoading ? "Please wait for a response..." : "Message Evatt"}
        className={`!bg-slate-800 !text-white !outline-none !m-0 !rounded-3xl !border-0 [&_.MuiInputBase-input]:custom-scrollbar ${isLoading ? 'opacity-80' : ''
          }`}
        sx={{
          '& .MuiInputBase-input::-webkit-scrollbar': {
            width: '8px',
          },
          '& .MuiInputBase-input::-webkit-scrollbar-track': {
            background: '#1e293b', // slate-800
          },
          '& .MuiInputBase-input::-webkit-scrollbar-thumb': {
            background: '#475569', // slate-600
            borderRadius: '4px',
          },
          '& .MuiInputBase-input::-webkit-scrollbar-thumb:hover': {
            background: '#64748b', // slate-500
          },
          '& .MuiInputBase-input::placeholder': {
            color: isLoading ? '#94a3b8 !important' : '#64748b !important', // slate-400 for loading, slate-500 for normal
            opacity: '1 !important',
          },
        }}
        slotProps={{
          input: {
            startAdornment: (
              <label className="self-start px-4 py-2 cursor-pointer">
                <AttachFileIcon fontSize="medium" className="text-white" />
                <input
                  type="file"
                  accept=".pdf, .doc, .docx"
                  hidden
                  onChange={handleChange}
                />
              </label>
            ),
            endAdornment: (
              <Box
                className={`py-2 px-4 cursor-pointer self-start ${(!userQuery || isLoading) && "opacity-40 pointer-events-none"
                  }`}
                onClick={() => {
                  if (userQuery && !isLoading) {
                    onSubmit(userQuery, attachment);
                    handleClose();
                   
                  }
                 
                }}
              >
                <SendIcon fontSize="medium" className="text-white" />
              </Box>
              
            ),
            disableUnderline: true,
            className: "!py-3 !text-white !font-bold",
          },
        }}
      />
    </Box>
  );
};

export default ChatInput;
