import axiosServices from "../utils/axios";

export const getUserDetails = (accessToken) => {
  try {
    return axiosServices.get("/auth/users/me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (error) {
    return error;
  }
};
