import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const LoadingScreen = () => {
  return (
    <Box className="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-screen bg-gray-900">
      <CircularProgress />
    </Box>
  )
}

export default LoadingScreen