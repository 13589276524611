import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title = "Confirm Action",
  message = "Are you sure you want to proceed?",
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="!rounded-[8px]" >
     <div className="bg-gray-900 ">
     <DialogTitle >
        <Typography variant="h6" className="text-white !font-[700]">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" className="text-white !font-[700]">{message}</Typography>
      </DialogContent>
      <DialogActions>
       <div className="flex gap-6 ">
         <Button onClick={onClose} className="!font-[700] !text-white"  >
            Cancel
          </Button>
           <Button onClick={onConfirm} className="!text-white !font-[700] !bg-red-500  hover:!bg-red-600 active:bg-green-800">
            Confirm
          </Button>
       </div>
      </DialogActions>
     </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
