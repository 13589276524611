import React, { useState, useEffect, createContext } from "react";
import useFetch from "../api/HttpRequest";
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from "react-router-dom";

export const UserContext = createContext({ user: null });

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const accessToken = Cookies.get('accessToken');
  const {data, error, responseCode, fetchData} = useFetch('/user/check', {
    method: 'GET',
    accessToken,
    silent: true,
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      if(location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forget-password' && location.pathname !== '/reset-password' && location.pathname !== '/access' && location.pathname !== '/account/activate/:id/:code') {
        navigate('/signin');
      }
    } else {
      fetchData();
    }
  }, [location]);

  useEffect(() => {
    if (data) {
      if (responseCode === "401") {
        Cookies.remove('accessToken');
        if (location.pathname !== '/signin' && location.pathname !== '/signup') {
          navigate('/signin');
        }
      } else if (data.status) {
        setUser(data.data);
      }
    } else if (error) {
      setUser(null);
      if (location.pathname !== '/signin' && location.pathname !== '/signup') {
        navigate('/signin');
      }
    }
  }, [data, error, responseCode, accessToken, location, navigate]);

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
};
