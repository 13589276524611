import { Box, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const InputField = ({
  placeholder,
  type,
  value,
  name,
  onChange,
  error,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  if (type === "password") {
    return (
      <TextField
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        variant="outlined"
        margin="normal"
        fullWidth
        error={error}
        helperText={helperText}
        name={name}
        className="!bg-transparent !text-white !outline-none !m-0 !p-0"
        slotProps={{
          input: {
            endAdornment: (
              <Box>
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <VisibilityIcon className="text-white cursor-pointer" />
                  ) : (
                    <VisibilityOffIcon className="text-white cursor-pointer" />
                  )}
                </IconButton>
              </Box>
            ),
            className:
              "!text-white !font-bold !border !border-gray-600 !rounded-xl",
          },
        }}
      />
    );
  }
  return (
    <TextField
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      error={error}
      helperText={helperText}
      variant="outlined"
      margin="normal"
      fullWidth
      name={name}
      className="!bg-transparent !text-white !outline-none !m-0 !p-0"
      slotProps={{
        input: {
          className:
            "!text-white !font-bold !border !border-gray-600 !rounded-xl",
        },

        select: {
          className: "focus:ring-violet-300",
        },
      }}
    />
  );
};

export default InputField;
