import React, { useState, useEffect } from "react";

const OTPTimer = ({ onTimerComplete, resetTimer }) => {
  const [timeLeft, setTimeLeft] = useState(60); // Start with 60 seconds

  useEffect(() => {
    if (resetTimer) {
      setTimeLeft(60); // Reset timer to 60 seconds when resetTimer changes
    }
  }, [resetTimer]);

  useEffect(() => {
    if (timeLeft === 0) {
      onTimerComplete(true); // Notify that the timer has expired
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer); // Clean up interval on component unmount or when timer ends
  }, [timeLeft]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return <span>{formatTime(timeLeft)}</span>;
};

export default OTPTimer;
