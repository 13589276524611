import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { modalArr } from "../utils/constants";

const ChatModelDropdown = ({ model, setModel }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event) => {
    setModel(event.target.value);
  };

  return (
    <FormControl variant="standard">
      <Select
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        className="!text-[16px] !font-bold !bg-transparent !text-white"
        value={model}
        onChange={handleChange}
        IconComponent={() =>
          isOpen ? (
            <KeyboardArrowUpIcon fontSize="small" className="text-white " />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" className="text-white " />
          )
        }
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "rgb(30 41 59)",
              margin: 0,
              padding: 0,
              "& .MuiMenuItem-root": {
                padding: 1.5,
              },
            },
          },
        }}
        disableUnderline
      >
        {modalArr.map((item, index) => (
          <MenuItem
            key={index}
            value={index}
            className="!text-[16px] !font-bold !text-white"
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChatModelDropdown;
