import React, { useState } from 'react';
import useFetch from "../api/HttpRequest";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
   CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Logo from "../assets/image.png";
import routeConstant from "../utils/routeConstant";
import { Link } from "react-router-dom";

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  const { data: resetData, error: resetError, fetchData: resetFetch } = useFetch('/user/password/change', {
    method: 'POST',
    silent: false,
    successMessage: 'Password reset',
  });

  const handleReset = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setError('');
    const formData = {
      id: 40, 
      password,
      tempKey: '256950', 
    };
    setLoading(true);
    try {
      const response = await resetFetch({ data: formData });

      if (response && response.success) {
        window.location.href = '/new-dashboard';  // Update this URL as needed
      } else {
        console.log("Password reset failed");
      }
    } catch (error) {
      console.log("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading after the request finishes
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box
      className="flex flex-col items-center min-h-screen gap-20 py-10 bg-gray-900 "
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      <Link
        to={routeConstant.dashboard}
        className="!text-[50px] !font-bold !text-white"
      >
        <img src={Logo} alt="logo" width={250} />
      </Link>
      <Box
        width="100%"
        maxWidth="400px"
        p={4}
        borderRadius={2}
      >
        <Typography className='text-white !mb-[25px]'  variant="h5" textAlign="center" marginBottom={2}>
          Reset Password
        </Typography>

     <div className=" flex flex-col gap-8">

     <TextField
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff className='text-white' /> : <Visibility className='text-white'/>}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              color: 'white', 
              backgroundColor: '', 
              borderRadius: '10px', 
        },
          }}
          InputLabelProps={{
            style: {
              color: '#a0a0a0', 
            },
          }}
          inputProps={{
            style: {
              color: 'white', 
             
              borderRadius: '10px', 
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'gray',  // Change this to your desired border color
              },
              '&:hover fieldset': {
                borderColor: 'blue',  // Hover color
              }
            },
          }}
           className="!bg-transparent !text-white !outline-none !m-0 !p-0"
        
        />    

        <TextField
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                  {showConfirmPassword ? <VisibilityOff  className='text-white'/> : <Visibility className='text-white'/>}
                </IconButton>
              </InputAdornment>
            ),
            
       style: {
          color: 'white', 
          backgroundColor: '', 
          borderRadius: '10px', 
    },
          }}
          InputLabelProps={{
            style: {
              color: '#a0a0a0', 
            },
          }}
          inputProps={{
            style: {
              color: 'white', 
             
              borderRadius: '10px', 
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'gray',  // Change this to your desired border color
              },
              '&:hover fieldset': {
                borderColor: 'blue',  // Hover color
              }
            },
          }}
           className=" !mb-6  !w-full"
        />

     </div>
        {error && (
          <Typography color="error" marginTop={1}>
            {error}
          </Typography>
        )}

        <Button
        className=' !bg-[#00A67E] !rounded-[8px] !h-[40px] '
          variant="contained"
          fullWidth
          onClick={handleReset}
          sx={{ marginTop: 2 }}
        >
            {loading ? (
        <CircularProgress size={24} color="inherit" />  
      ) : (
        'Reset Password'
      )}
        </Button>
      </Box>
    </Box>
  );
};

export default ResetPassword;
