import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../api/HttpRequest";
import Cookies from "js-cookie";

const BookingSuccess = () => {
  const { bookingId, code } = useParams();
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

  const { data: activateResponse, fetchData: activateFetch } = useFetch('/payment/success', {
    method: 'POST',
    data: {
      id: bookingId,
      code: code,
    },
    silent: false,
  });

  useEffect(() => {
    if (bookingId && code && !hasFetched.current) {
      hasFetched.current = true;
      activateFetch();
    }
  }, [bookingId, code, activateFetch]);

  useEffect(() => {
    if (activateResponse?.data?.accessToken) {
      Cookies.set('accessToken', activateResponse.data.accessToken);
      window.location.href = '/';
    } else {
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }
  }, [activateResponse]);

  return (
    <div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default BookingSuccess;
