import axiosServices from "../utils/axios";

export const authRegister = (userData) => {
  try {
    return axiosServices.post("/auth/register", userData);
  } catch (error) {
    return error;
  }
};

export const authLogin = (userData) => {
  try {
    return axiosServices.post("/auth/login", userData, {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    });
  } catch (error) {
    return error;
  }
};

export const sendOTPEmail = (userData) => {
  try {
    return axiosServices.post("/auth/generateotp", userData);
  } catch (error) {
    return error;
  }
};
