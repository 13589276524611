import axios from "axios";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => error
);

export default axiosServices;

// IGNORE THIS PART FOR NOW

// import axios from "axios";

// const axiosServices = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
// });

// axiosServices.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("AccessToken");
//     if (token) {
//       config.headers["Authorization"] = token;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject((error.response && error.response.data) || "Wrong Services")
// );

// export default axiosServices;
