import React, { useEffect, useState } from 'react';
import { IconButton, Avatar, Menu, MenuItem, Button } from "@mui/material";
import Cookies from "js-cookie";
import useFetch from "../api/HttpRequest";
import LoadingScreen from './LoadingScreen';

const AccountMenu = ({ user, setAccessToken, finalQueryCount, trailSubscriptionResponse, fetchTrailSubscriptionAdd }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const accessToken = Cookies.get('accessToken');
  const { fetchData: logoutFetch } = useFetch('/user/logout', {
    method: 'GET',
    silent: true,
  });

  const { data: manageSubscriptionResponse, fetchData: fetchManageSubscriptionAdd } = useFetch('/subscription/manage/subscription', {
    method: 'GET',
    accessToken,
    silent: true,
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logoutFetch();
    setAccessToken(null);
    Cookies.remove('accessToken');
    window.location.reload();
  };

  const handleUpgradeToTrailClick = async () => {
    handleMenuClose();
    await fetchTrailSubscriptionAdd();
  };

  useEffect(() => {
    if (trailSubscriptionResponse?.status) {
      window.location.href = trailSubscriptionResponse.data.payment_link;
    }
  }, [trailSubscriptionResponse]);

  useEffect(() => {
    if (manageSubscriptionResponse?.status) {
      window.location.href = manageSubscriptionResponse.data;
    }
  }, [manageSubscriptionResponse]);

  const renderMenuItems = () => {
    if (user?.subscriptionValidity.enterprise) {
      return (
        <div>
          <MenuItem disabled>{user?.email}</MenuItem>
          <MenuItem disabled>Your Plan (Enterprise)</MenuItem>
          <MenuItem onClick={fetchManageSubscriptionAdd}>Manage Subscription</MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </div>
      );
    } else if (user?.subscriptionValidity.trail) {
      return (
        <div>
          <MenuItem disabled>{user?.email}</MenuItem>
          <MenuItem disabled>Your Plan (Trial)</MenuItem>
          <MenuItem disabled>Days remaining: {user?.daysRemaining}</MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </div>
      );
    } else {
      return (
        <div>
          <MenuItem disabled>{user?.email}</MenuItem>
          <MenuItem>
            <Button variant="contained" color="primary" onClick={handleUpgradeToTrailClick}>Upgrade (14 days Trail)</Button>
          </MenuItem>
          <MenuItem>Query count left: {15 - (finalQueryCount || 0)}</MenuItem>
          <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        </div>
      );
    }
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <Avatar src="/path/to/profile/image.jpg" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              borderRadius: '12px',
              backgroundColor: '#1e293b',
              color: 'white',
              width: 'fit-content',
            }
          }
        }}
      >
        {renderMenuItems()}
      </Menu>
    </>
  );
};

export default AccountMenu;