export const planModalData = [
  {
    type: "Free Trial",
    description: "Designed to Trial Evatt AI",
    price: "0",
    priceDescription: "Upgrades to Enterprise after 14 days",
    buttonText: "Continue with Free",
    chatGptVersion: " ",
    features: [
      "OpenAI's ChatGPT 4o, 4 & 3.5",
      "100 000 credits/month",
      "Response upto 4 000 words",
      "Access to Default Templates"
    ]
  },
  {
    type: "Enterprise Plan",
    description: "Designed for Leading Law Firms",
    price: "249",
    priceDescription: "AUD / Month",
    buttonText: "Upgrade to Enterprise",
    chatGptVersion: "Minimum 3 accounts",
    features: [
      "Access to Advanced Case Search",
      "Priority access to models such as ChatGPT 5",
      "OpenAI's ChatGPT 4o, 4 & 3.5",
      "Unlimited credits/month",
      "Response upto 4 000 words",
      "Access to Premium Templates",
      "Priority support on phone",
      "Database of onboarding & training tools",
      "Faster response time"
    ]
  },
];

export const modalArr = [
  {
    name: "ChatGPT 3.5",
    modal: "gpt-3.5-turbo",
  },
  {
    name: "ChatGPT 4",
    modal: "gpt-4",
  },
  {
    name: "ChatGPT 4o",
    modal: "gpt-4o",
  },
];
