import { Button, Typography } from "@mui/material";
import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/FacebookRounded";

const AuthButtons = ({ type, mode }) => {
  const renderIcon = () => {
    switch (type) {
      case "Google":
        return <GoogleIcon fontSize="small" className="!text-white" />;
      case "Apple":
        return <AppleIcon fontSize="small" className="!text-white" />;
      case "Facebook":
        return <FacebookIcon fontSize="small" className="!text-white" />;
      default:
        return null;
    }
  };

  const handleAuthClick = () => {
    if (type === "Google") {
      window.location.href = 'https://stg-auth.evattai.com/auth/google';
    }
  };

  return (
    <Button
      sx={{ border: "2px solid #2d3748" }}
      className="!rounded-lg !py-1 !px-6 !text-nowrap !text-[14px] !font-bold !flex !justify-center !items-center gap-2 !bg-transparent hover:!bg-slate-700 !text-white !capitalize"
      onClick={() => handleAuthClick()}
    >
      {renderIcon()}
      <Typography className="!text-[14px] !font-bold !text-white">
        {mode === "signin" ? "Continue" : "Sign Up"} with {type}
      </Typography>
    </Button>
  );
};

export default AuthButtons;
