import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ link, bg, border, children }) => {
  return (
    <Link
      to={link}
      className={`!border ${border} !rounded-3xl !py-2 !px-6 !text-[16px] !font-bold !flex !justify-center !items-center ${bg} hover:!bg-slate-700 !text-white !capitalize`}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
